.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}

.show-pdf-icon {
    font-size: 25px;
}

.btn-preview-icon {
    position: absolute !important;
    top: 34px;
    left: 18px;
}

.pdf-embed {
    margin-top: 6px;
}

.btn-update-icon {
    position: absolute !important;
    top: 34px;
    left: 55px;
}

.pdf-icon {
    font-size: 30px;
}

.pdf-container {
}
