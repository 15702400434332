.compta {
  display: flex;
  flex-direction: row;
}

#id_accounts-form {
  display: none;
}

#id_sales-form {
  display: none;
}

#id_management-form {
  display: none;
}

.editable-cell {
  position: relative;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.width-p-80 {
  width: 80% !important;
}

.extra-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: white;
  padding: 0 24px;
}

.card-clients-create {
  margin: 25px auto !important;
}

.max-width-900 {
  max-width: 900px;
}
