.extra-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: white;
    padding: 0 24px;
}

.card-partner-create {
    margin: 25px auto !important;
    width: 80% !important;
    min-width: 600px !important;
    max-width: 700px !important;
}
