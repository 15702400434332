.label-copied-antd {
    position: relative;
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}

.span-label-copied-antd {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.extra-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: white;
    padding: 0 24px;
}

.card-user-create {
    margin: 25px auto !important;
    width: 80% !important;
    min-width: 600px !important;
    max-width: 700px !important;
}
