#error {
  max-width: 1280px;
  min-height: calc(100vh - 72px - 107px);
  padding: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#error .contact {
  margin-top: 16px;
  font-size: 18px;
}

#error .contact .email {
  cursor: pointer;
}

#error .contact .email:hover {
  text-decoration: underline;
}
