@import '../../../assets/colors';

#error {
  max-width: 1280px;
  min-height: calc(100vh - 72px - 107px);
  padding: 24px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;

  .contact {
    margin-top: 16px;
    font-size: 18px;

    .email {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
