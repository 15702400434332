#row_header_data {
  display: flex;
  flex-direction: row;
  width: 70%;
}

#add_remitter {
  /*width: 30%;*/
  display: flex;
  justify-content: flex-end;
}

.margin-vertical {
  margin-top: 16px;
  margin-bottom: 16px;
}
