.default-width-select {
    width: 350px !important;
}

.default-space-filter {
    margin-bottom: 8px !important;
}

.margin-vertical {
    margin-top: 16px;
    margin-bottom: 16px;
}
