.compta {
  display: flex;
  flex-direction: row;
}

#id_accounts-form {
  display: none;
}

#id_sales-form {
  display: none;
}

#id_management-form {
  display: none;
}

.editable-cell {
  position: relative;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}