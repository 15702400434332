#components-layout-demo-side .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.ant-table-column-sorters {
    justify-content: center !important;
}

.ant-table-tbody {
    cursor: pointer;
}