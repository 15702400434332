.error-drag-and-drop-left {
    border-bottom-color: #f5222d !important;
    border-top-color: #f5222d !important;
    border-left-color: #f5222d !important;
}

.error-drag-and-drop-right {
    border-bottom-color: #f5222d !important;
    border-top-color: #f5222d !important;
    border-right-color: #f5222d !important;
}

.drag_left {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    border-radius: 4px 0 0 4px;
    border: 1px solid rgb(217, 217, 217);
    border-right-style: none;
}

.flex-center-start {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.drag_right {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    border-radius: 0 4px 4px 0;
    border: 1px solid rgb(217, 217, 217);
}

.draggable_container {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sub_draggable_container {
    background-color: white;
    min-height: 80px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.draggable_item {
    user-select: none;
    padding: 10px 20px 16px 20px;
    margin: 0px 0px 10px 0px;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
    background-color: #34495E;
    text-align: left;
    color: white;
}

.sub_draggable_item {
    user-select: none;
    padding: 16px;
    margin: 0px 0px 10px 0px;
    width: 100%;
    max-width: 300px;
    border-radius: 4px;
    background-color: #34495E;
    text-align: left;
    color: white;
}

.draggable_item_dragging {
    user-select: none;
    padding: 16px;
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
    background-color: #2C3E50;
    text-align: left;
    color: white;
}

.info-container-step {
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin: 0px;
}

.title-partner-step {
    font-size: 15px;
    width: 60%;
    margin: 0px;
}

.sub_draggable_item_dragging {
    user-select: none;
    padding: 16px;
    width: 100%;
    max-width: 300px;
    border-radius: 4px;
    background-color: #2C3E50;
    text-align: left;
    color: white;
}

.column_header {
    vertical-align:middle;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top:0;
    margin-bottom:0;
    background-color: transparent;
}

.droppable-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.required-fields {
    color: #f5222d;
    font-size: 14px;
    line-height: 1.5715;
}

.required-fields-error {
    color: #f5222d;
    margin: 0px;
    font-size: 14px;
    line-height: 1.5715;
}

.close-button {
    font-size: 20px;
}

.close-container {
    width: 5%;
    text-align: end;
    align-items: start;
    display: flex;
    justify-content: end;
}

.input-container {
    width: 35%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hide {
    display: none;
}

.row-between {
    display: flex;
    justify-content: space-between;
}

.info-container {
    width: 60%;
    margin: 0px;
}

.header-container {
    background-color: transparent;
    width: 100%;
    border-bottom: 1px solid rgb(217,217,217);
}

.context-container {
    display: flex;
    height: 700px;
    text-align: center;
    overflow: auto;
}
